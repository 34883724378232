var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 1200,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单号",
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "orderCode",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入订单号!",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "['orderCode', {rules: [{ required: true, message: '请输入订单号!' }]}]",
                              },
                            ],
                            attrs: { placeholder: "请输入订单号" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单类型",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["ctype", {}],
                                  expression: "['ctype',{}]",
                                },
                              ],
                              attrs: { placeholder: "请输入订单类型" },
                            },
                            [
                              _c("a-select-option", { attrs: { value: "1" } }, [
                                _vm._v("国内订单"),
                              ]),
                              _c("a-select-option", { attrs: { value: "2" } }, [
                                _vm._v("国际订单"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单日期",
                          },
                        },
                        [
                          _c("a-date-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["orderDate", {}],
                                expression: "[ 'orderDate',{}]",
                              },
                            ],
                            attrs: {
                              showTime: "",
                              format: "YYYY-MM-DD HH:mm:ss",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { staticClass: "form-row", attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单金额",
                          },
                        },
                        [
                          _c("a-input-number", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["orderMoney", {}],
                                expression: "[ 'orderMoney', {}]",
                              },
                            ],
                            staticStyle: { width: "200px" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { lg: 8 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "订单备注",
                          },
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["content", {}],
                                expression: "['content', {}]",
                              },
                            ],
                            attrs: { placeholder: "请输入订单备注" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-tabs",
                { attrs: { defaultActiveKey: "1" } },
                [
                  _c("a-tab-pane", { key: "1", attrs: { tab: "客户信息" } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "a-row",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { type: "flex", gutter: 16 },
                          },
                          [
                            _c("a-col", { attrs: { span: 5 } }, [
                              _vm._v("客户名"),
                            ]),
                            _c("a-col", { attrs: { span: 5 } }, [
                              _vm._v("性别"),
                            ]),
                            _c("a-col", { attrs: { span: 5 } }, [
                              _vm._v("身份证号码"),
                            ]),
                            _c("a-col", { attrs: { span: 5 } }, [
                              _vm._v("手机号"),
                            ]),
                            _c("a-col", { attrs: { span: 4 } }, [
                              _vm._v("操作"),
                            ]),
                          ],
                          1
                        ),
                        _vm._l(
                          _vm.orderMainModel.jeecgOrderCustomerList,
                          function (item, index) {
                            return _c(
                              "a-row",
                              {
                                key: index,
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { type: "flex", gutter: 16 },
                              },
                              [
                                _c(
                                  "a-col",
                                  {
                                    staticStyle: { display: "none" },
                                    attrs: { span: 6 },
                                  },
                                  [
                                    _c(
                                      "a-form-item",
                                      [
                                        _c("a-input", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "jeecgOrderCustomerList[" +
                                                  index +
                                                  "].id",
                                                { initialValue: item.id },
                                              ],
                                              expression:
                                                "['jeecgOrderCustomerList['+index+'].id', {'initialValue':item.id}]",
                                            },
                                          ],
                                          attrs: { placeholder: "id" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      [
                                        _c("a-input", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "jeecgOrderCustomerList[" +
                                                  index +
                                                  "].name",
                                                {
                                                  initialValue: item.name,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: "请输入用户名!",
                                                    },
                                                  ],
                                                },
                                              ],
                                              expression:
                                                "['jeecgOrderCustomerList['+index+'].name', {'initialValue':item.name,rules: [{ required: true, message: '请输入用户名!' }]}]",
                                            },
                                          ],
                                          attrs: { placeholder: "客户名" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      [
                                        _c(
                                          "a-select",
                                          {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "jeecgOrderCustomerList[" +
                                                    index +
                                                    "].sex",
                                                  { initialValue: item.sex },
                                                ],
                                                expression:
                                                  "['jeecgOrderCustomerList['+index+'].sex', {'initialValue':item.sex}]",
                                              },
                                            ],
                                            attrs: { placeholder: "性别" },
                                          },
                                          [
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "1" } },
                                              [_vm._v("男")]
                                            ),
                                            _c(
                                              "a-select-option",
                                              { attrs: { value: "2" } },
                                              [_vm._v("女")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      [
                                        _c("a-input", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "jeecgOrderCustomerList[" +
                                                  index +
                                                  "].idcard",
                                                {
                                                  initialValue: item.idcard,
                                                  rules: [
                                                    {
                                                      pattern:
                                                        "^\\d{6}(18|19|20)?\\d{2}(0[1-9]|1[012])(0[1-9]|[12]\\d|3[01])\\d{3}(\\d|[xX])$",
                                                      message:
                                                        "身份证号格式不对!",
                                                    },
                                                  ],
                                                },
                                              ],
                                              expression:
                                                "['jeecgOrderCustomerList['+index+'].idcard', {'initialValue':item.idcard,rules: [{ pattern: '^\\\\d{6}(18|19|20)?\\\\d{2}(0[1-9]|1[012])(0[1-9]|[12]\\\\d|3[01])\\\\d{3}(\\\\d|[xX])$', message: '身份证号格式不对!' }]}]",
                                            },
                                          ],
                                          attrs: { placeholder: "身份证号" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 5 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      [
                                        _c("a-input", {
                                          directives: [
                                            {
                                              name: "decorator",
                                              rawName: "v-decorator",
                                              value: [
                                                "jeecgOrderCustomerList[" +
                                                  index +
                                                  "].telphone",
                                                {
                                                  initialValue: item.telphone,
                                                  rules: [
                                                    {
                                                      pattern:
                                                        "^1(3|4|5|7|8)\\d{9}$",
                                                      message:
                                                        "手机号格式不对!",
                                                    },
                                                  ],
                                                },
                                              ],
                                              expression:
                                                "['jeecgOrderCustomerList['+index+'].telphone', {'initialValue':item.telphone,rules: [{ pattern: '^1(3|4|5|7|8)\\\\d{9}$', message: '手机号格式不对!' }]}]",
                                            },
                                          ],
                                          attrs: { placeholder: "手机号" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-col",
                                  { attrs: { span: 4 } },
                                  [
                                    _c(
                                      "a-form-item",
                                      [
                                        _c("a-button", {
                                          attrs: { icon: "plus" },
                                          on: { click: _vm.addRowCustom },
                                        }),
                                        _vm._v(" \n                  "),
                                        _c("a-button", {
                                          attrs: { icon: "minus" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delRowCustom(index)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "a-tab-pane",
                    { key: "2", attrs: { tab: "机票信息", forceRender: "" } },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "a-row",
                            {
                              staticStyle: { "margin-bottom": "10px" },
                              attrs: { type: "flex", gutter: 16 },
                            },
                            [
                              _c("a-col", { attrs: { span: 6 } }, [
                                _vm._v("航班号"),
                              ]),
                              _c("a-col", { attrs: { span: 6 } }, [
                                _vm._v("航班时间"),
                              ]),
                              _c("a-col", { attrs: { span: 6 } }, [
                                _vm._v("操作"),
                              ]),
                            ],
                            1
                          ),
                          _vm._l(
                            _vm.orderMainModel.jeecgOrderTicketList,
                            function (item, index) {
                              return _c(
                                "a-row",
                                {
                                  key: index,
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { type: "flex", gutter: 16 },
                                },
                                [
                                  _c(
                                    "a-col",
                                    {
                                      staticStyle: { display: "none" },
                                      attrs: { span: 6 },
                                    },
                                    [
                                      _c(
                                        "a-form-item",
                                        [
                                          _c("a-input", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "jeecgOrderTicketList[" +
                                                    index +
                                                    "].id",
                                                  { initialValue: item.id },
                                                ],
                                                expression:
                                                  "['jeecgOrderTicketList['+index+'].id', {'initialValue':item.id}]",
                                              },
                                            ],
                                            attrs: { placeholder: "id" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        [
                                          _c("a-input", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "jeecgOrderTicketList[" +
                                                    index +
                                                    "].ticketCode",
                                                  {
                                                    initialValue:
                                                      item.ticketCode,
                                                    rules: [
                                                      {
                                                        required: true,
                                                        message:
                                                          "请输入航班号!",
                                                      },
                                                    ],
                                                  },
                                                ],
                                                expression:
                                                  "['jeecgOrderTicketList['+index+'].ticketCode', {'initialValue':item.ticketCode,rules: [{ required: true, message: '请输入航班号!' }]}]",
                                              },
                                            ],
                                            attrs: { placeholder: "航班号" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        [
                                          _c("j-date", {
                                            directives: [
                                              {
                                                name: "decorator",
                                                rawName: "v-decorator",
                                                value: [
                                                  "jeecgOrderTicketList[" +
                                                    index +
                                                    "].tickectDate",
                                                  {
                                                    initialValue:
                                                      item.tickectDate,
                                                  },
                                                ],
                                                expression:
                                                  "['jeecgOrderTicketList['+index+'].tickectDate', {'initialValue':item.tickectDate}]",
                                              },
                                            ],
                                            attrs: {
                                              placeholder: "航班时间",
                                              "trigger-change": true,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "a-form-item",
                                        [
                                          _c("a-button", {
                                            attrs: { icon: "plus" },
                                            on: { click: _vm.addRowTicket },
                                          }),
                                          _vm._v(" \n                  "),
                                          _c("a-button", {
                                            attrs: { icon: "minus" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delRowTicket(index)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }